//本地导入
import request from "../request";

//获取渠道列表
export const getChannelList = () =>
  request({
    method: "post",
    url: "/adm/channel/all",
  });

//添加渠道
export const addChannel = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/channel/add",
  });

//修改渠道
export const editChannel = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/channel/edit",
  });
