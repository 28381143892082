
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { addChannel, editChannel, getChannelList } from "@/api/request/channel";

//组件
@Component({
  name: "ChannelList",
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private listLoading: boolean = false;

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getChannelList();

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理添加
  private handleAdd(): void {
    //初始化数据
    this.form = {
      //临时数据
      channel_idStr: "",

      //提交数据
      id: undefined,
      remark: "", //渠道备注
      reg_code: "", //注册码
      channel_id: 0, //渠道ID
      channel_url: "", //渠道URL
      channel_name: "", //渠道名称
    };

    //显示界面
    this.dialogShow = true;
  }

  //处理编辑
  private handleEdit(row: any): void {
    //初始化数据
    this.form = {
      //临时数据
      channel_idStr: String(row.channel_id),

      //提交数据
      id: row.id,
      remark: row.remark, //渠道备注
      reg_code: row.reg_code, //注册码
      channel_id: row.channel_id, //渠道ID
      channel_url: row.channel_url, //渠道URL
      channel_name: row.channel_name, //渠道名称
    };

    //显示界面
    this.dialogShow = true;
  }

  //------------------------------- 添加/编辑系统消息 -------------------------------
  //表单数据
  private form: any = {
    //临时数据
    channel_idStr: "",

    //提交数据
    id: undefined,
    remark: "", //渠道备注
    reg_code: "", //注册码
    channel_id: 0, //渠道ID
    channel_url: "", //渠道URL
    channel_name: "", //渠道名称
  };

  //定义变量
  private dialogShow: boolean = false;
  private dialogLoading: boolean = false;

  //保存按钮
  private btneSave() {
    //异常消息
    if (Number(this.form.channel_idStr) == 0) return this.$message.error("请输入渠道ID");
    if (this.form.channel_name == "") return this.$message.error("请选择消息类型");
    if (this.form.channel_url == "") return this.$message.error("请选择发布对象");
    if (this.form.reg_code == "") return this.$message.error("请输入注册码");
    if (this.form.remark == "") return this.$message.error("请输入内容");

    //数据赋值
    this.form.channel_id = Number(this.form.channel_idStr);

    //处理添加
    if (this.isCreate()) {
      //处理添加消息
      this.handleAddMsg();
    }
    //处理更新
    else {
      //处理更新消息
      this.handleUpdataMsg();
    }
  }

  //取消按钮
  private btnCancel(): void {
    //隐藏界面
    this.dialogShow = false;
  }

  //是否创建
  private isCreate(): boolean {
    return this.form.id === undefined;
  }

  // //处理添加消息
  private async handleAddMsg() {
    //显示等待
    this.dialogLoading = true;

    //获取数据
    await addChannel(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogShow = false;

      //加载列表
      this.getList();
    }, 0.5 * 1000);
  }

  //处理更新消息
  private async handleUpdataMsg() {
    //显示等待
    this.dialogLoading = true;

    //获取数据
    await editChannel(this.form);

    //隐藏等待
    setTimeout(() => {
      //隐藏等待
      this.dialogLoading = false;

      //隐藏对话框
      this.dialogShow = false;

      //加载列表
      this.getList();
    }, 0.5 * 1000);
  }
}
